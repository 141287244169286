import React from 'react'
import { formatDate, ignoreDID } from 'utils'

export default function SingleRegistrationData({ registration }) {
	const {
		serial_number,
		serial_number_secondary,
		did,
		aid,
		date_of_purchase,
		dealer_name
	} = registration

	const _ignoreDID = ignoreDID(registration.product_id)

	return (
		<div className='singleRegistration-data'>
			<ul>
				<li>
					<span>Serial number</span>
					{serial_number || '(No serial number)'}
				</li>
				{serial_number_secondary ?
					<li>
						<span>Serial number (Rope light)</span>
						{serial_number_secondary || '(No serial number)'}
					</li>
					: null}
				{_ignoreDID ? null :
					<li>
						<span>Device ID (DID)</span>
						{did || '(No DID)'}
					</li>
				}
				{(!registration.product.uses_pearson_activation && !_ignoreDID) ? (
					<li>
						<span>AID</span>
						{aid || '(No AID)'}
					</li>
				) : null}
				<li>
					<span>Purchased from</span>
					{dealer_name ? dealer_name : '(No dealer)'}
				</li>
				<li>
					<span>Date of purchase</span>
					{formatDate(date_of_purchase)}
				</li>
			</ul>
		</div>
	)
}
